@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.hero-bg {
		background-image: url("./assets/images/hero-bg.png");
		background-position: 100% 0px;
		background-size: 160%;
		background-repeat: no-repeat;
	}

	@media (min-width: 768px) {
		.hero-bg {
			background-position: right center;
			background-size: cover;
		}
	}
}
